<template>
  <div class="login">
    <hNav />
    <div class="content">

      <div class="main">

      </div>
    </div>
    <bNav />
  </div>
</template>


<script>
  import hNav from './components/hnav.vue'
  import bNav from './components/bnav.vue'
  export default {
    components: {
      hNav,
      bNav,
    },
    data: function() {
      return {

      };
    },

    methods: {

    },
  };
</script>

<style lang="less" scoped>
  .content {
    background: #3179c9;
    height: 600px;
    min-width: 1180px;
  }
  .main {
    width: 1180px;
    margin: auto;
    font-size: 12px;
    text-align: left;
    position: relative;

  }
</style>
